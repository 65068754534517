.menu {
    display: none;
    margin: 0;
}

.menuBlue {
    composes: menu;
}

.menu > ul > li:hover > span,
.menu > ul > li:hover > a,
.menu .active,
.active {
    border-bottom: 4px solid #ff1330;
}

.menuBlue > ul > li:hover > span,
.menuBlue > ul > li:hover > a,
.menuBlue .active {
    border-bottom: 4px solid #379cdd;
}

.menu > ul > li:hover,
.menu > ul > li:focus-within {
    cursor: pointer;
}

.menu > ul > li:focus-within span,
.menu > ul > li:focus-within a {
    outline: none;
}

.toggle {
    display: inline-block;
    -webkit-user-select: none;
    user-select: none;
    float: left;
    cursor: pointer;
}

.toggle > svg {
    width: 32px;
    height: 32px;
}

.toggleBlack {
    composes: toggle;
    margin-right: 12px;
}

.toggleBlack > svg {
    width: 26px;
    height: 26px;
}

.toggleOpen {
    display: none;
}

@media (min-width: 990px) {
    .menu {
        display: block;
    }

    .menu > ul > li {
        position: relative;
        display: inline-block;
    }

    .menu > ul > li > span,
    .menu > ul > li > a {
        margin: 0 10px;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;
    }

    .toggle {
        display: none;
    }

    .dropdown {
        visibility: hidden;
        transition: all 0.5s ease;
        display: none;
        opacity: 0;

        position: absolute;
        z-index: 3;
    }

    .dropdownInner {
        display: flex;
        position: relative;
        margin-top: 18px;
        background: #fff;
    }

    .dropdownInner:before {
        display: block;
        content: "";
        position: absolute;
        top: -10px;
        left: 18px;

        border-style: solid;
        border-width: 0 9px 10px 9px;
        border-color: transparent transparent #fff transparent;
    }

    .menu > ul > li:hover > .dropdown,
    .menu > ul > li:focus-within > .dropdown,
    .dropdown:hover,
    .dropdown:focus {
        visibility: visible;
        opacity: 1;
        display: block;
    }

    .column {
        width: 200px;
        font-size: 13px;
    }

    .columnInner {
        margin: 20px 0 20px 20px;
        border-right: 1px solid #d7d7d7;
    }

    .column:last-child > .columnInner {
        border-right: 0;
    }

    .title {
        font-weight: 600;
        text-align: left;
        margin-bottom: 15px;
    }

    .item {
        display: block;
        margin: 0 0 10px 5px;
        color: #ff0a2e;
        text-decoration: none;
        font-weight: 500;
        text-align: left;
    }

    .menuBlue .item {
        color: #379cdd;
    }
}

@media (min-width: 1200px) {
    .menu > ul > li > span,
    .menu > ul > li > a {
        margin: 0 40px 0 0;
    }
}
