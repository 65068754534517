.container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background: #fff;
    width: 100%;
    height: 100%;
}

:global(.ReactModal__Body--open) .container {
    display: none;
}

.header {
    display: flex;
    padding: 20px 10px 14px 10px;
    border-bottom: 1px solid #d6d6d6;
}

.logo {
    flex: 1;
}

.left,
.right {
    flex: 1;
    vertical-align: middle;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.closeButton,
.loginButton {
    display: inline-block;
    position: relative;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
    color: #000;
    padding-top: 22px;
    cursor: pointer;
}

.closeButton:before,
.loginButton:before {
    display: block;
    position: absolute;
    content: "";
    margin-left: 50%;
    top: 0;
    left: -11px;
    width: 20px;
    height: 20px;
    background: url("/static/icons/close.svg") center center no-repeat;
    background-size: 100%;
}

.loginButton:before {
    background: url("/static/icons/sign-in.svg") center center no-repeat;
    background-size: 100%;
    top: -1px;
}

.menu {
    margin: 20px 10px;
    list-style-type: none;
}

.menu li {
    padding-bottom: 10px;
}

.menuLink {
    display: block;
    position: relative;
    height: 40px;
    line-height: 40px;
    text-decoration: none;
    color: #0e2650;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    padding-left: 20px;
    cursor: pointer;
}

.menuLink:after {
    right: 20px;
    top: 11px;
    border-color: #000;
    border-width: 2px 2px 0 0;
    border-style: solid;
    transform: rotate(45deg);
    content: " ";
    height: 16px;
    width: 16px;
    position: absolute;
    pointer-events: none;
}

.menu li:nth-child(1) .menuLink:after {
    border-color: #ed566b;
}

.menu li:nth-child(2) .menuLink:after {
    border-color: #20bad6;
}

.menu li:nth-child(3) .menuLink:after {
    border-color: #52bd9d;
}

.menu li:nth-child(4) .menuLink:after {
    border-color: #fb9e5a;
}

.signupText {
    margin: 0 20px;
    color: #0e2650;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.signupText > div {
    color: #ff0000;
    cursor: pointer;
}

.terms {
    position: absolute;
    bottom: 10px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    left: 50%;
    margin-left: -140px;
}

.terms li > a {
    color: #ababab;
    text-decoration: none;
    font-size: 13px;
    cursor: pointer;
}

.termsDim {
    content: "-";
    display: block;
    width: 20px;
    color: #ababab;
    font-size: 13px;
    text-align: center;
    padding-top: 3px;
}
