.footer {
    display: flex;
    flex-direction: column;
    background-color: #132239;
}

.copyright {
    padding: 10px 0;
    background-color: #0b1422;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
}

.copyrightWrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1170px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.menu {
    flex: 1;
    padding: 20px 10px;
}

.menuWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-left: 15px;
    max-width: 1170px;
    width: 100%;
    height: 100%;
}

.menuList {
    margin-top: 20px;
    width: 50%;
}

.menuTitle {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

.menuItem {
    display: block;
    color: #999;
    font-size: 12px;
    text-decoration: none !important;
    margin-bottom: 10px;
    cursor: pointer;
}

.menuItem:hover {
    color: #ddd;
}

.content {
}

.contentCollapsed {
    composes: content;
    max-height: 0;
    overflow: hidden;
}

.more {
    display: block;
    position: relative;
    top: -12px;
    color: #c6372f;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
}

.more:hover {
    color: #ff372f;
}

.moreIcon {
    position: relative;
    top: 8px;
    color: #999;
}

.moreIcon:hover {
    color: #ddd;
}

@media (min-width: 768px) {
    .copyrightWrapper {
        flex-direction: row;
    }

    .menu {
        padding: 30px 0 20px 0;
    }

    .menuList {
        margin-top: 0;
        width: 25%;
    }
}

@media (max-width: 500px) {
    .copyright {
        padding: 5px 0;
    }

    .menuWrapper {
        width: 100%;
        text-align: center;
    }

    .menuList {
        margin-top: 20px;
        width: 100%;
    }

    .menuTitle {
        padding-bottom: 18px;
    }

    .menuList .more,
    .menuList .menuItem {
        min-height: 48px;
        margin: -10px 0 0 0;
    }
}
