.copyrightText {
    text-transform: uppercase;
}

.copyrightLinks .copyrightLink {
    font-size: 11px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
}

.copyrightLinks .copyrightLink:after {
    display: inline-block;
    content: "|";
    margin: 0 10px;
    font-size: 10px;
}

.copyrightLinks .copyrightLink:last-child:after {
    display: none;
}

.socialLinks {
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
}

.socialLinks > a {
    width: 19px;
    height: 19px;
}

.socialLinks > a > svg {
    width: 19px;
    height: 19px;
    fill: #fff;
}

@media (min-width: 768px) {
    .copyrightLinks {
        margin-bottom: 0;
    }

    .copyrightText {
        flex: 1;
    }
}
